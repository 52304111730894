.contact-us {
  padding: 50px 140px;
}

.bg-light-blue {
  background-color: #28a7451a;
}

.bg-white {
  background-color: #f8ffff;
}

.contact-us__frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 26px;
}

.contact-us__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 24px;
}

.contact-us__title {
  color: #1e1e1e;
  font-family: "Montserrat-Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
}

.contact-us__subtitle {
  color: #1e1e1e;
  font-family: "Montserrat-Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
}

.contact-us__text {
  display: inline;
  color: #1e1e1e;
  font-family: "Lato", Helvetica;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}

.contact-us__email {
  color: #007bff;
  font-weight: 600;
}

.contact-us__phone {
  color: #28a745;
  font-weight: 600;
}



.contact-us__image {
  max-width: 100%;
  height: 400px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .contact-us {
    padding: 30px;
  }

  .contact-us__content {
    gap: 5px;
  }

  .contact-us__title {
    font-size: 16px;
  }

  .contact-us__subtitle {
    font-size: 32px;
  }

  .contact-us__text {
    font-size: 18px;
  }

  .contact-us__image {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .contact-us {
    padding: 20px;
  }

  .contact-us__title {
    font-size: 10px;
  }

  .contact-us__subtitle {
    font-size: 14px;
  }

  .contact-us__text {
    font-size: 9px;
  }

  .contact-us__image {
    height: 150px;
  }
}
