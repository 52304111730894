.navigating-success {
    align-items: center;
    background-color: var(--basewhite);
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 40px 80px;
    box-sizing: border-box;
    max-width: 2200px;
  }
  
  .navigating-success .container {
    align-items: flex-start;
    display: flex;
    gap: 32px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .navigating-success .heading {
    color: var(--black);
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 32px;
    text-align: left;
    font-weight: 600;
    line-height: 1.2;
    margin-top: -1px;
  }
  
  .navigating-success .content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 30px;
  }
  
  .navigating-success .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 60%;
    box-sizing: border-box;
  }
  
  .navigating-success .feature-text {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
  }
  
  .navigating-success .featured-icon-instance {
    margin-left: -4px !important;
    margin-top: -4px !important;
  }
  
  .navigating-success .content-2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .navigating-success .text-and-supporting {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 10px;
  }
  
  .navigating-success .text {
    color: var(--primary-colour);
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 22px;
    font-weight: 500;
  }
  
  .navigating-success .supporting-text {
    color: var(--gray-600);
    font-family: "Lato-Regular", Helvetica;
    font-size: 1.25rem;
    line-height: 1.5;
    width: 100%;
    max-width: 600px;
  }
  
  .navigating-success .design-component-instance-node {
    background-color: #ffd9b9 !important;
    border-color: #fff3ea !important;
    margin-left: -4px !important;
    margin-top: -4px !important;
  }
  
  .navigating-success .text-wrapper {
    color: #fd7e14;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 22px;
    font-weight: 500;
  }
  
  .navigating-success .featured-icon-2 {
    background-color: #d6fedf !important;
    border-color: #ebfff0 !important;
    margin-left: -4px !important;
    margin-top: -4px !important;
  }
  
  .navigating-success .text-2 {
    color: #28a745;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 22px;
    font-weight: 500;
  }
  
  .navigating-success .content-3 {
    display: flex;
    justify-content: center;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    width: 40%;
    height: 600px;
  }
  
  .navigating-success .content-3 img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
  }
  
