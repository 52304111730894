.navigating-success-md-sm {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 20px;
    position: relative;
  }
  
  .navigating-success-md-sm .text-wrapper {
    align-self: stretch;
    color: #000000;
    font-family: "Lato-SemiBold", Helvetica;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 31px;
    margin-top: -1px;
    position: relative;
  }
  
  .navigating-success-md-sm .frame {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  
  .navigating-success-md-sm .div {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    padding: 10px 0px;
    position: relative;
    width: 100%;
  }
  
  .navigating-success-md-sm .rectangle {
    height: 300px;
    object-fit: cover;
    position: relative;
    width: auto;
  }
  
  .navigating-success-md-sm .frame-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .navigating-success-md-sm .text-wrapper-2 {
    color: var(--primary-colour);
    font-family: "Lato-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 43px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .navigating-success-md-sm .p {
    align-self: stretch;
    color: #000000;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5;
    position: relative;
  }
  
  .navigating-success-md-sm .text-wrapper-3 {
    color: #fd7e14;
    font-family: "Lato-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 43px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .navigating-success-md-sm .text-wrapper-4 {
    color: var(--secondary-colour);
    font-family: "Lato-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 43px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  @media screen and (min-width: 481px) and (max-width: 780px) {

    .navigating-success-md-sm {
        padding: 40px 80px;
    }

    .navigating-success-md-sm .text-wrapper {
        max-width: 500px;
    }
    .navigating-success-md-sm .div {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        gap: 30px;
        justify-content: center;
        padding: 10px 0px;
        position: relative;
        width: 100%;
      }

      .navigating-success-md-sm .frame-2 {
        width: 50%;
    }

    .navigating-success-md-sm .rectangle {
        width: 50%;
    }

    .navigating-success-md-sm .p {
        align-self: stretch;
        color: #000000;
        font-family: "Montserrat-Regular", Helvetica;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.5;
        position: relative;
    }
  }