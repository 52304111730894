/* Base styles for badge-group */
.badge-group {
  align-items: center;
  background-color: #eaf4ff;
  border: 1px solid;
  border-color: #c4e0ff;
  border-radius: 16px;
  display: flex;
  position: relative;
}

/* Styles for large size */
.badge-group.lg {
  height: 52px; 
  gap: 12px;
  padding: 5px 15px;
}

.badge-group.lg .badge {
  padding: 2px 10px;
}

.badge-group.lg .text,
.badge-group.lg .message {
  font-size: var(--text-sm-medium-font-size);
  font-weight: var(--text-sm-medium-font-weight);
}

.badge-group.lg .arrow-right {
  height: 16px;
  width: 16px;
}

/* Styles for medium size */

.badge-group.md {
  height: 20px;
  padding: 5px 8px;
  gap: 8px;
}

.badge-group.md .badge {
  padding: 1px 10px;
  margin: -5px;
}

.badge-group.md .text,
.badge-group.md .message {
  font-size: 14px;
  font-weight: var(--text-sm-medium-font-weight);
}

.badge-group.md .arrow-right {
  height: 12px;
  width: 12px;
}

/* Common styles */
.badge-group .badge {
  align-items: center;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: #c4e0ff;
  border-radius: 16px;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.badge-group .text,
.badge-group .message {
  color: var(--primary-colour);
  font-family: var(--text-sm-medium-font-family);
  font-style: var(--text-sm-medium-font-style);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.badge-group .content {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}
