.explore-events-card {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
}

.explore-events-card .image {
  height: 227px;
  object-fit: cover;
  width: 416px;
  border-radius: 13.76px 13.76px;
}

.explore-events-card .frame {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.explore-events-card .content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.explore-events-card .div {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.explore-events-card .heading {
  color: var(--black);
  font-family: "Montserrat-SemiBold", Helvetica;
  font-size: 22px;
  font-weight: 600;
}

.explore-events-card .supporting-text {
  color: var(--gray-600);
  font-family: "Lato-Regular", Helvetica;
  font-size: 20px;
  text-align: left;
  line-height: 1.5;
}

.explore-events-card .div-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  border-bottom: 2px solid var(--primary-colour);
}

.explore-events-card .text-wrapper {
  color: var(--primary-colour);
  font-family: "Lato-SemiBold", Helvetica;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
}

/* Media queries for small screens */
@media (max-width: 768px) {
  .explore-events-card {
    flex-direction: column;
    gap: 20px;
  }

  .explore-events-card .heading {
    font-size: 20px;
  }

  .explore-events-card .supporting-text {
    font-size: 18px;
  }
}


@media (max-width: 480px) {
  .explore-events-card {
    gap: 20px;
      align-items: center;
      display: flex;
      flex-direction: column;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 13.76px 13.76px;
      overflow: hidden;
  }

  .explore-events-card .frame {
    background-color: #ffffff;
    border: 0.86px solid #f2f2f7;
    border-top: 0;
    padding: 17.2px;
  }

  .explore-events-card .image {
    height: auto;
    object-fit: cover;
    width: 100%;
    border-radius: 0;
  }

  .explore-events-card .heading {
    font-size: 20px;
    line-height: 26.7px;
  }

  .explore-events-card .supporting-text {
    font-size: 14px;
  }

  .explore-events-card .div-wrapper {
    border-bottom: 1.72px solid #007bff;
    gap: 8.6px;
    height: 48px;
    justify-content: center;
    padding: 16px 32px;
  }

.explore-events-card .text-wrapper {
  font-size: 16px;
  line-height: 26.7px;
}
}